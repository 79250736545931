





























import { Vue, Component } from 'vue-property-decorator';

@Component({
  head: {
    title() {
      return {
        inner: '개인정보 수집 및 이용 동의'
      }
    }
  }
})
export default class Collection extends Vue {
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
}
